<template>
  <div class="page_bg" style="background:rgb(24, 87, 176)">
    <div class="invite_bg">
      <van-image width="4.35rem" height="0.68rem" style="margin-top:0.43rem"
                 src="https://oss.xiaoyi120.com/shop2.0/invite/invite_logo.png"
      />
      <van-image width="9.15rem" height="3.17rem"
                 src="https://oss.xiaoyi120.com/shop2.0/invite/inviteRegSuccess_title.png"
      />
      <van-image width="10rem" height="11.2rem"
                 src="https://oss.xiaoyi120.com/shop2.0/invite/inviteRegSuccess_card_01.png"
      />
      <div class="invite_msg flex-r-sa-c" style="margin-top:0.56rem">
        <div class="invite_msg_item flex-c-c-c">
          <div class="item_index">1.</div>
          <div class="item_msg">长按/扫码</div>
          <div class="item_msg">关注公众号</div>
        </div>
        <div class="invite_msg_item flex-c-c-c">
          <div class="item_index">2.</div>
          <div class="item_msg">点击优选商城</div>
          <div class="item_msg">进入商城</div>
        </div>
        <div class="invite_msg_item flex-c-c-c">
          <div class="item_index">3.</div>
          <div class="item_msg">个人中心</div>
          <div class="item_msg">领优惠券</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    }
  }
}
</script>

<style>
.invite_bg {
  width: 100%;
  min-height: 15.02rem;
  background: url("https://oss.xiaoyi120.com/shop2.0/invite/inviteReg_bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 2rem;
}
.invite_msg_item {
  width: 2.77rem;
  height: 2.59rem;
  background: url("https://oss.xiaoyi120.com/shop2.0/invite/inviteRegSuccess_msg_bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 0.4rem;
}
.item_index,
.item_msg {
  font-size: 0.4rem;
  color: #ffffff;
}
</style>
